/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withPrefix, graphql } from 'gatsby';

// Helpers
import { filterResults } from '../../helpers/helpers';
import { checkNull } from '../../helpers/check-null';

// Layouts
import Default from '../../layouts/default';
import Seo from '../../layouts/seo';

// Components
import Section from '../../components/section';
import Form from '../../components/form';
import InputSwitch from '../../components/input-switch';
import Select from '../../components/select';
import PageHeader from '../../components/page-header';
import ResultListing from '../../components/result-listing';
import InputField from '../../components/input-field';
import ShareResults from '../../components/share-results';
import Content, { HTMLContent } from "../../components/content-renderer";

// Helpers
import withShareResults from '../../helpers/with-share-results';

const DividendTax = (props) => {
    const { getShareLink, selectedYearParam } = withShareResults();
    const PageContent = HTMLContent || Content;

	const pageInfo = props.data.toolInfo;
    const definitions = props.data.definitions.nodes;
    const headerImage = pageInfo.frontmatter.image.relativePath;

	// React Redux hooks
	const dispatch = useDispatch();
	const figures = useSelector(state => state.yearlyFigures);
    const query = useSelector(state => state.dividendTax);

    useEffect(() => {
        return () => {
            dispatch({ type: 'RESET_YEAR' });
            dispatch({ type: 'RESET_DIVIDEND_TAX_SELECTIONS' });
        };
    }, []);

    //  Applies url params on load
    useEffect(() => {
        if (selectedYearParam) {
            dispatch({ type: 'SET_YEAR', payload: selectedYearParam });
        }

        dispatch({ type: 'APPLY_DIVIDEND_TAX_URL_PARAMS' });
    }, [selectedYearParam]);

    const income = checkNull(query.grossIncome);
    const dividends = checkNull(query.dividend);

	// Calculate salary based on 'Paid per' period input
	const calcGrossIncome = (query.paidPer === 'year') ? income :
		(query.paidPer === 'month') ? (income * 12) :
		(query.paidPer === 'fortnight') ? (income * 26) :
		(query.paidPer === 'week') ? (income * 52) :
		(query.paidPer === 'day') ? ((income * query.workingDays) * 52) :
		(query.paidPer === 'hour') ? (((income * query.workingHours) * query.workingDays) * 52) :
        null;
        
    const calcDividendIncome = (query.paidPer === 'year') ? dividends :
        (query.paidPer === 'month') ? (dividends * 12) :
        (query.paidPer === 'fortnight') ? (dividends * 26) :
        (query.paidPer === 'week') ? (dividends * 52) :
        (query.paidPer === 'day') ? ((dividends * query.workingDays) * 52) :
        (query.paidPer === 'hour') ? (((dividends * query.workingHours) * query.workingDays) * 52) :
        null;

	// Personal Allowance Reduction
	// Personal Allowance goes down by £1 for every £2 that net income is above 100,000 (https://www.gov.uk/income-tax-rates/income-over-100000)
	const calcPersonalAllowanceReduction = (calcGrossIncome >= 100000) ? ((calcGrossIncome - 100000) / 2) : 0;

	// Blindness Allowance is applied apply new figure (https://www.gov.uk/blind-persons-allowance/what-youll-get)
	const checkBlindnessAllowance = query.blind ? figures.personalAllowance + figures.blindAllowance :
		figures.personalAllowance;

	// Calculate corrected Personal Allowance with reduction if gross wage > 100,000
	const calcPersonalAllowance = (checkBlindnessAllowance - calcPersonalAllowanceReduction);
    const calcCorrectedPersonalAllowance = (calcPersonalAllowance < 0) ? '0.00' : calcPersonalAllowance;
    
    // Total Taxable Dividend Income calculation
    const calcTaxableDividendIncome = (dividends - figures.dividendAllowance) <= 0 ? 0 : dividends - figures.dividendAllowance;

	// Total Taxable Income calculation
	const calcTaxableIncome = (calcGrossIncome - calcCorrectedPersonalAllowance + calcTaxableDividendIncome);
	const calcCorrectedTaxableIncome = (calcTaxableIncome < 0) ? '0.00' : calcTaxableIncome;

	// Total income per year
	const totalIncome = parseFloat(calcGrossIncome) + dividends;

    // Dividend Tax calculation
    // https://www.gov.uk/tax-on-dividends
	const calcDividendTax = (totalIncome <= figures.basicRateBand) ? (dividends - figures.dividendAllowance) * figures.dividendBasicRate :
		(totalIncome > figures.basicRateBand) && (totalIncome <= figures.higherRateBand) ? (dividends - figures.dividendAllowance) * figures.dividendHigherRate :
		(totalIncome > figures.higherRateBand) ? (dividends - figures.dividendAllowance) * figures.dividendAdditionalRate :
		null;

	const calcCorrectedDividendTax = calcDividendTax <= 0 ? '0.00' : calcDividendTax;

    return (
        <Default>
            <Seo
                title={pageInfo.frontmatter.title}
                description={pageInfo.frontmatter.description}
            />

            <PageHeader
                heading={pageInfo.frontmatter.title}
                imageSource={`${withPrefix("/")}img/${headerImage}`}
                modalContent={(
                    <PageContent content={pageInfo.html} />
                )}
                description={pageInfo.frontmatter.description}
                breadcrumbs={[
                    {name: 'Home', url: '/'},
                    {name: 'Personal finance', url: '/personal-finance'},
                ]}
            />
            
            <Section label='Calculator'>
                <Form>
                    <Form.Fieldset>
                        <Form.Row>
                            <InputField
                                id='gross-income'
                                labelText='Gross income'
                                modalHeading={definitions[2].frontmatter.title}
                                modalContent={<PageContent content={definitions[2].html} />}
                                placeholder="0"
                                symbol='currency'
                                min={0}
                                onChange={(event) => dispatch({
                                    type: 'SET_DIVIDEND_TAX_GROSS_INCOME',
                                    payload: event.target.value,
                                })}
                                required
                                value={query.grossIncome}
                            />

                            <InputField
                                id='dividend'
                                required
                                labelText='Dividend income'
                                modalHeading={definitions[1].frontmatter.title}
                                modalContent={<PageContent content={definitions[1].html} />}
                                placeholder="0"
                                symbol='currency'
                                min={0}
                                onChange={(event) => dispatch({
                                    type: 'SET_DIVIDEND_TAX_DIVIDEND',
                                    payload: event.target.value,
                                })}
                                value={query.dividend}
                            />

                            <Select
                                labelText="Paid per"
                                id="paid-select"
                                onChange={(event) => dispatch({
                                    type: 'SET_DIVIDEND_TAX_PAID_PER',
                                    payload: event.target.value,
                                })}
                                value={query.paidPer}
                            >
                                <Select.Option value="year">Year</Select.Option>
                                <Select.Option value="month">Month</Select.Option>
                                <Select.Option value="fortnight">Fortnight</Select.Option>
                                <Select.Option value="week">Week</Select.Option>
                                <Select.Option value="day">Day</Select.Option>
                                <Select.Option value="hour">Hour</Select.Option>
                            </Select>
                        </Form.Row>

                        <Form.Row>
                            <InputField
                                id='days-per-week'
                                labelText='Working days per week'
                                placeholder="0"
                                min={1}
                                max={7}
                                onChange={(event) => dispatch({
                                    type: 'SET_DIVIDEND_TAX_WORKING_DAYS',
                                    payload: event.target.value,
                                })}
                                value={query.workingDays}
                            />

                            <InputField
                                id='hours-per-day'
                                labelText='Working hours per day'
                                placeholder="7"
                                min={1}
                                max={24}
                                onChange={(event) => dispatch({
                                    type: 'SET_DIVIDEND_TAX_WORKING_HOURS',
                                    payload: event.target.value,
                                })}
                                value={query.workingHours}
                            />

                            <Select
                                labelText="Tax year"
                                id="tax-year-select"
                                onChange={(event) => dispatch({
                                    type: 'SET_YEAR',
                                    payload: event.target.value,
                                })}
                                value={figures.selectedYear}
                            >
                                <Select.Option value='2024'>2024/25</Select.Option>
                                <Select.Option value='2023'>2023/24</Select.Option>
                                <Select.Option value='2022'>2022/23</Select.Option>
                                <Select.Option value='2021'>2021/22</Select.Option>
                                <Select.Option value='2020'>2020/21</Select.Option>
                                <Select.Option value='2019'>2019/20</Select.Option>
                                <Select.Option value='2018'>2018/19</Select.Option>
                                <Select.Option value='2017'>2017/18</Select.Option>
                                <Select.Option value='2016'>2016/17</Select.Option>
                            </Select>
                        </Form.Row>

                        <Form.Row>
                            <InputSwitch
                                id="blind-switch"
                                onChange={() => dispatch({
                                    type: 'SET_DIVIDEND_TAX_BLIND',
                                    payload: !query.blind,
                                })}
                                checked={query.blind}
                                modalHeading={definitions[0].frontmatter.title}
                                modalContent={<PageContent content={definitions[0].html} />}
                            >
                                Blind
                            </InputSwitch>
                        </Form.Row>
                    </Form.Fieldset>
                </Form>
            </Section>

            <Section
				heading='Your results'
				label='Calculation results'
				variants={['padded', 'secondary']}
			>
                <ResultListing
                    select={[
                        {name: 'Year', value: 'year'},
                        {name: 'Month', value: 'month'},
                        {name: 'Week', value: 'week'},
                        {name: 'Day', value: 'day'},
                        {name: 'Hour', value: 'hour'},
                    ]}
                    selectID='results-filter'
                    selectOnChange={(event) => dispatch({
                        type: 'SET_DIVIDEND_TAX_FILTER_RESULTS',
                        payload: event.target.value,
                    })}
                    selectValue={query.filterResults}
                >
                    <ResultListing.Item
                        heading='Total gross income'
                        result={filterResults(totalIncome, query.filterResults, query.workingDays, query.workingHours)}
                        subheading={`per ${query.filterResults}`}
                    />

                    <ResultListing.Item
                        heading='Dividend income'
                        result={filterResults(calcDividendIncome, query.filterResults, query.workingDays, query.workingHours)}
                        subheading={`per ${query.filterResults}`}
                    />

                    <ResultListing.Item
                        heading='Taxable income'
                        result={filterResults(calcCorrectedTaxableIncome, query.filterResults, query.workingDays, query.workingHours)}
                        subheading={`per ${query.filterResults}`}
                    />

                    <ResultListing.Item
                        heading='Dividend tax'
                        result={filterResults(calcCorrectedDividendTax, query.filterResults, query.workingDays, query.workingHours)}
                        subheading={`per ${query.filterResults}`}
                    />
                </ResultListing>

                <div className='summary-card__controls'>
                    <ShareResults url={getShareLink(query, figures)} />
                </div>
            </Section>
        </Default>
    );
};

export default DividendTax;

export const DividendTaxQuery = graphql`
    query DividendTaxQuery($id: String!) {
        toolInfo: markdownRemark(id: { eq: $id }) {
            id
            html
            frontmatter {
                description
                image {
                    relativePath
                }
                title
            }
        }
        definitions: allMarkdownRemark(
            filter: {fileAbsolutePath: {
                regex: "/src/definitions/gross-income|src/definitions/dividend-income|src/definitions/blindness-allowance/"
            }},
            sort: {order: ASC, fields: frontmatter___title}
        ) {
            nodes {
                html
                frontmatter {
                    title
                }
            }
        }
    }
`;